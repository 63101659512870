import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { Badge } from "../../components/Badge";
import { OrganizationUser, Vendor } from "./type";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { UsersListQuery, UsersListQueryEventFilter } from "./queries";
import { organizationStore } from "../../store/organization";
import { useEffect, useRef, useState } from "react";
import { eventStore } from "../../store/event";
import moment from "moment/moment";
import { usePermissions } from "../auth/api";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Input } from "../../components/inputs/Input";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { AgGridReact } from "ag-grid-react";
import { userStore } from "../../store/user";
import { GET_LIST_EVENTS_BY_ORGANIZATION } from "../../queries";
import { Event } from "../../types/event";
import { VENDORS_LIST_QUERY } from "../vendors/queries";
import UserImportCSVModal from "../../components/importCsv/UserImportCSVModal";

const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getEventStore } = eventStore;
const { getState: getUserStore } = userStore;

const buildSearch = (search: string) => [
  {
    role: {
      name: {
        _ilike: `%${search}%`,
      },
    },
  },
  {
    phone_number: {
      _ilike: `%${search}%`,
    },
  },
  {
    username: {
      _ilike: `%${search}%`,
    },
  },
];

type CellRenderer = (params: {
  value:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  data: OrganizationUser;
}) => JSX.Element | null;
const createCellRenderer = (
  permission: boolean,
  linkFunc: (params: { data: OrganizationUser }) => string
): CellRenderer => {
  return (params) => {
    if (params.value === undefined) {
      return null;
    }

    const values = Array.isArray(params.value) ? params.value : [params.value];
    return (
      <>
        {values.map((value, index) => (
          <Badge key={index}>
            {permission ? (
              <Link to={linkFunc({ data: params.data })}>{value}</Link>
            ) : (
              value
            )}
          </Badge>
        ))}
      </>
    );
  };
};

export const usersListLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = url.searchParams.get("search");
  const rawOrganizationId = getState().organizationId;
  const organizationId = parseInt(
    rawOrganizationId !== null ? rawOrganizationId.toString() : "",
    10
  );
  const eventId = getEventStore().eventId;
  const user = getUserStore().user;
  let where: any = {};
  let vendorWhere: any = {};
  let eventWhere: any = { _or: search ? buildSearch(search) : undefined };
  if (organizationId && organizationId !== 0) {
    where = {
      ...where,
      organization_id: {
        _eq: organizationId,
      },
    };
  }
  if (organizationId && organizationId !== 0) {
    vendorWhere = {
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };
  }
  if (organizationId && organizationId !== 0) {
    eventWhere = {
      ...eventWhere,
      organization_id: {
        _eq: organizationId,
      },
    };
  }
  if (eventId) {
    eventWhere = {
      ...eventWhere,
      events: {
        _contains: `${eventId}`,
      },
    };
  }
  if (user?.events) {
    where = {
      ...where,
      id: {
        _in: user?.events,
      },
    };
  }
  console.log("eventID: ", eventId);
  const [{ data: usersData }, { data: eventsData }, { data: vendorsData }] =
    await Promise.all([
      client.query({
        query: UsersListQueryEventFilter,
        variables: {
          eventWhere,
        },
      }),
      client.query({
        query: GET_LIST_EVENTS_BY_ORGANIZATION,
        variables: {
          where,
        },
      }),
      client.query({
        query: VENDORS_LIST_QUERY,
        variables: {
          vendorWhere,
        },
      }),
    ]);
  return {
    users: usersData.users,
    events: eventsData.events,
    vendors: vendorsData.vendors,
  };
};

export const UsersList = () => {
  const { editCreatePermission, ListPermission, moduleWisePermission } =
    usePermissions("users");
  const { editCreatePermission: eventsProcessorEditPermission } =
    usePermissions("events");
  const { editCreatePermission: rolesProcessorEditPermission } =
    usePermissions("roles");
  const { editCreatePermission: vendorsProcessorEditPermission } =
    usePermissions("vendors");
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const gridRef = useRef<GridApi>();
  const { users, events, vendors } = useLoaderData() as {
    users: OrganizationUser[];
    events: Event[];
    vendors: Vendor[];
  };
  const [rowData, setRowData] = useState<OrganizationUser[]>([]);
  useEffect(() => {
    setRowData(users);
  }, [users]);

  const eventMap = events.reduce((map, event) => {
    map[event.id] = event.name;
    return map;
  }, {} as { [key: string]: string });

  const vendorMap = vendors.reduce((map, vendor) => {
    map[vendor.id] = vendor.name;
    return map;
  }, {} as { [key: string]: string });

  const generateColDefs = (): ColDef<OrganizationUser>[] => [
    {
      headerName: "Id",
      valueGetter: (p) => p.data?.id,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/users/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Username",
      valueGetter: (p) => p.data?.username,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/users/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Is Active?",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (params) => {
        return params.value ? "Yes" : "No";
      },
    },
    {
      headerName: "First Name",
      valueGetter: (p) => p.data?.first_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Last Name",
      valueGetter: (p) => p.data?.last_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Event(s)",
      valueGetter: (p) => {
        const eventIds = p.data?.events || [];
        return eventIds.map((id) => ({
          id,
          name: eventMap[id],
        }));
      },
      cellRenderer: (params: any) => {
        const events = params.value;
        return events.map((event: any, index: any) => (
          <Badge key={index}>
            <Link to={`/events/${event.id}/edit`}>{event.name}</Link>
          </Badge>
        ));
      },
      filter: false,
    },
    {
      headerName: "Role",
      valueGetter: (p) => p.data?.role?.display_name,
      cellRenderer: createCellRenderer(
        rolesProcessorEditPermission,
        (p) => `/roles/${p.data.role.id}/edit`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Vendor(s)",
      valueGetter: (p) => {
        const vendorIds = p.data?.vendors || [];
        return vendorIds.map((id) => ({
          id,
          name: vendorMap[id],
        }));
      },
      cellRenderer: (params: any) => {
        const vendors = params.value;
        return vendors.map((vendor: any, index: any) => (
          <Badge key={index}>
            <Link to={`/vendors/${vendor.id}/edit`}>{vendor.name}</Link>
          </Badge>
        ));
      },
      filter: false,
    },
    {
      headerName: "Updated By",
      valueGetter: (p) => p.data?.user?.username,
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];

  const colDefs: ColDef<OrganizationUser>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `Users_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <div style={{ height: "75vh" }}>
      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: "100%" }}
      >
        <div className="grid gap-2 grid-cols-6">
          <Breadcrumbs />
          <div className="col-span-2 col-start-5 mt-2">
            <Input
              name="search"
              placeholder={`Search Users`}
              onChange={(s) => {
                navigate(
                  s && typeof s === "string" && s.length > 0
                    ? `/users/list?search=${s}`
                    : `/users/list`
                );
                revalidator.revalidate();
              }}
            />
          </div>
          <div className="py-2 col-span-2 col-start-7">
            {/*<UserImportCSVModal onConfirm={() => {}}>*/}
            {/*  {({ setOpen }) => (*/}
            {/*    <PrimaryButton onClick={() => setOpen(true)}>*/}
            {/*      Import CSV*/}
            {/*    </PrimaryButton>*/}
            {/*  )}*/}
            {/*</UserImportCSVModal>*/}
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
            {editCreatePermission && (
              <PrimaryButton onClick={() => navigate(`/users/create`)}>
                Create
              </PrimaryButton>
            )}
          </div>
        </div>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          rowHeight={rowHeight}
          autoSizeStrategy={autoSizeStrategy}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={100}
          onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};
